import styled from 'styled-components';
import Logo from '../Assets/Images/logo.png';
import HeaderImage from '../Assets/Images/header-bg@2x.png';

const Header = () => {
  return (
    <HeaderLogoWrapper>
      <HeaderPhoto src={HeaderImage} />
      <HeaderLogo src={Logo} />
      <CustomText>دار الشروق للنشر والتوزيع</CustomText>
    </HeaderLogoWrapper>
  );
};

export default Header;

const HeaderLogo = styled.img`
  max-height: inherit;
  width: 165px;
  height: auto;
  margin-top: 1%;
  margin-right: 4%;
  margin-bottom: -3%;
  @media (max-width: 475px) {
    display: none;
  }
`;

export const HeaderPhoto = styled.img`
  max-height: 250px;
  float: left;
`;

export const CustomText = styled.p`
  color: #4c7a91;
  font-weight: 500;
  font-size: 140%;
  margin-right: 1%;
  @media (max-width: 475px) {
    display: none;
  }
`;

export const HeaderLogoWrapper = styled.div`
  width: 100%;
  text-align: end;
`;
