import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import Footer from './Components/Footer';
import Header from './Components/Header';
import UploadFiles from './Components/UploadFiles';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <div>
        <Header /> <UploadFiles /> <Footer />
      </div>
    ),
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
