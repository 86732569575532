import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import styled from 'styled-components';
import axios from 'axios';
import { API_URL } from '../Configs';

const fileTypes = ['PDF', 'ZIP', 'WORD', 'DOCX'];

const MainPage = () => {
  const [file, setFile] = useState(null);
  const [selectLanguage, setSelectLanguage] = useState('ara');
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [email, setEmail] = useState<any>(null);
  const [username, setUsername] = useState<any>(null);
  const [password, setPassword] = useState<any>(null);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [token, setToken] = useState('');

  const uploadFiles = async (file: any) => {
    setErrorMsg('');
    setSuccessMsg('');
    axios({
      method: 'post',
      url: `${API_URL}/shorok-login`,
      data: {
        username: username,
        password: password,
      },
    })
      .then((data) => {
        if (data.status === 201 && !!data.data) {
          setToken(data.data);
        }
      })
      .catch(() => {
        setErrorMsg('اسم المستخدم او كلمة المرور غير صحيحة');
      });

    let url = '';

    switch (file.type) {
      case 'application/pdf':
        url = `${API_URL}/tesseract/convert-pdfs`;
        break;
      case 'application/zip':
        url = `${API_URL}/tesseract/upload-compressed`;

        break;
      case 'application/word':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/docx':
        url = `${API_URL}/upload-book`;

        break;
    }

    const fileName = file.name.split('.')[0];

    const body = new FormData();
    body.append('file', file);
    body.append('book_name', fileName);
    body.append('language', selectLanguage);
    body.append('email', email);
    body.append('password', password);
    body.append('username', username);

    axios({
      method: 'post',
      url: url,
      data: body,
      headers: {
        'Accept-Content': 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((result) => {
        console.log('result: ', result);
        if (result.status === 201 && result.data) {
          setFile(null);
          setErrorMsg('');
          setSuccessMsg(`تم تحميل الكتاب بنجاح`);
        }
      })
      .catch((error) => {
        if (error) {
          setFile(null);
          setSuccessMsg('');
          if (!token) {
            setErrorMsg('اسم المستخدم او كلمة المرور غير صحيحة');
          } else {
            setErrorMsg('حدث خطأ ما، يرجى المحاولة مرة أخرى');
          }
        }

        return error;
      });

    setFile(null);
  };

  const handleSelectLanguageChange = (event: any) => {
    const value = event.target.value;
    setSelectLanguage(value);
  };

  const usernameEntered = (e: any) => {
    if (passwordInvalid) {
      setPasswordInvalid(false);
    }
    setUsername(e.target.value);
  };

  const emailEntered = (e: any) => {
    setEmail(e.target.value);
  };

  const passwordEntered = (e: any) => {
    if (passwordInvalid) {
      setPasswordInvalid(false);
    }

    setPassword(e.target.value);
  };

  const setTheFile = (theFile: any) => {
    setFile(theFile);
  };

  return (
    <>
      <Wrapper>
        <StyledForm
          onSubmit={(e) => {
            e.preventDefault();
            uploadFiles(file);
          }}
          dir="rtl"
        >
          <DragDropStyle dir="ltr">
            <FileUploader
              handleChange={(theFile: any) => {
                setSuccessMsg('');
                setErrorMsg('');
                setTheFile(theFile);
              }}
              name="file"
              types={fileTypes}
            />
          </DragDropStyle>

          <StyleInputLabel dir="rtl">
            <StyledFormInputsLabel>البريد الإلكتروني:</StyledFormInputsLabel>
            <StyledFormInput
              type="email"
              value={email}
              onChange={(e) => emailEntered(e)}
              required
            />
          </StyleInputLabel>
          <StyleInputLabel dir="rtl">
            <StyledFormInputsLabel>
              اسم مستخدم مدير النظام:
            </StyledFormInputsLabel>

            <StyledFormInput
              required
              type="text"
              value={username}
              onChange={(e) => usernameEntered(e)}
            />
          </StyleInputLabel>
          <StyleInputLabel dir="rtl">
            <StyledFormInputsLabel>
              كلمة مرور مدير النظام:
            </StyledFormInputsLabel>
            <StyledFormInput
              required
              type="password"
              value={password}
              onChange={(e) => passwordEntered(e)}
            />
          </StyleInputLabel>

          <RadioButtonWrapper dir="rtl">
            <Text>لغة الكتاب:</Text>
            <Item>
              <RadioButton
                type="radio"
                name="radio"
                value="ara"
                checked={selectLanguage === 'ara'}
                onChange={(event) => handleSelectLanguageChange(event)}
                id="arabic"
              />
              <RadioButtonLabel htmlFor="arabic">
                <LabelText>عربي</LabelText>
              </RadioButtonLabel>
            </Item>
            <Item>
              <RadioButton
                type="radio"
                name="radio"
                value="eng"
                checked={selectLanguage === 'eng'}
                onChange={(event) => handleSelectLanguageChange(event)}
                id="english"
              />
              <RadioButtonLabel htmlFor="english">
                <LabelText>إنجليزي</LabelText>
              </RadioButtonLabel>
            </Item>
          </RadioButtonWrapper>

          <Button
            type="submit"
            disabled={!email && !file && !username && !password}
          >
            تحويل الكتاب
          </Button>

          {errorMsg === '' ? (
            successMsg === '' ? (
              ''
            ) : (
              <p dir="rtl" style={{ color: 'green' }}>
                {successMsg}
              </p>
            )
          ) : (
            <p dir="rtl" style={{ color: 'red' }}>
              {errorMsg}
            </p>
          )}
        </StyledForm>
      </Wrapper>
    </>
  );
};

export default MainPage;

const Button = styled.button`
  color: #4d8c81;
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid #4d8c81;
  border-radius: 3px;
  cursor: pointer;
`;

const Text = styled.span`
  margin-left: 10px;
`;
const LabelText = styled.span`
  padding-right: 25px;
`;

const RadioButtonWrapper = styled.div`
  margin-bottom: -15px;
  width: 100%;
  margin-top: 3%;
`;

const Item = styled.span`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
  display: inline-block;
  padding-right: 100px;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
  border: 1px solid #4d8c81;
  cursor: pointer;
  left: 100px;
`;
const RadioButton = styled.input`
  visibility: hidden;
  &:hover ~ ${RadioButtonLabel} {
    background: #6a938b;
  }
  ${(props) =>
    props.checked &&
    ` 
    &:checked + ${RadioButtonLabel} {
      background: #4d8c81;
      border: 1px solid #4d8c81;
    }
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 400px;
  text-align: -webkit-center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 38px;
  @media (max-width: 475px) {
    margin-top: 0px;
  }
`;

const DragDropStyle = styled.div`
  padding-top: 2%;
  padding-bottom: 2%;
  width: 50%;
  @media (max-width: 475px) {
    margin-right: 38px;
    width: 74%;
  }
  @media (max-width: 320px) {
    width: 88%;
  }
  @media (max-width: 375px) {
    width: 82%;
    margin-right: 22px;
  }
`;

const StyledFormInputsLabel = styled.p`
  margin-bottom: 7px;
`;

const StyledFormInput = styled.input`
  width: 60%;
  padding: 10px;
  border: 1px solid #4d8c81;
  border-radius: 5px;
  color: #4d8c81;
  font-size: 1em;
  font-weight: 300;
  text-align: center;
  &:focus {
    color: #4d8c81;
    border: 1px solid #4d8c81;
    outline: none !important;
  }
`;

const StyleInputLabel = styled.div`
  width: 40%;
  margin-top: 2%;
  @media (max-width: 475px) {
    width: 100%;
  }
`;

const StyledForm = styled.form`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;
