import styled from 'styled-components';
import Facebook from '../Assets/Images/facebook.svg';
import Instagram from '../Assets/Images/instagram.svg';
import Twitter from '../Assets/Images/twitter.svg';
import WhiteLogo from '../Assets/Images/white-logo.svg';

const Footer = () => {
  return (
    <>
      <Wrapper>
        <LogoWithSocial>
          <HeaderLogo src={WhiteLogo} />
          <SocialRow>
            <TwitterIcon
              src={Twitter}
              onClick={() => window.open('https://twitter.com/daralshorok')}
            />
            <FacebookIcon
              src={Facebook}
              onClick={() => window.open('https://www.facebook.com/shorokjo')}
            />
            <InstagramIcon
              src={Instagram}
              onClick={() => window.open('https://www.instagram.com/')}
            />
          </SocialRow>
        </LogoWithSocial>
      </Wrapper>
    </>
  );
};

export default Footer;

const HeaderLogo = styled.img`
  width: 120px;
  height: 65px;
  padding-right: 8%;
`;

export const Wrapper = styled.div`
  background-color: #4d8c81;
  padding: 24px 100px;
  @media (max-width: 475px) {
    width: 52%;
  }
  @media (max-width: 320px) {
    width: 60%;
  }
`;

const InstagramIcon = styled.img`
  width: 20px;
  height: 20px;
  background-size: cover !important;
  cursor: pointer;
`;

const TwitterIcon = styled.img`
  width: 20px;
  height: 20px;
  background-size: cover !important;
  cursor: pointer;
`;
const FacebookIcon = styled.img`
  width: 20px;
  height: 20px;
  background-size: cover !important;
  cursor: pointer;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 0 20px;
  flex-wrap: wrap;
  @media (max-width: 475px) {
    flex-direction: column;
  }
`;

const SocialRow = styled(RowWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row-reverse;
  border-top: 1px solid #fff;
  padding-top: 10px;
  margin-top: 4px;
`;

export const LogoWithSocial = styled.div`
  width: 22%;
  box-sizing: border-box;
  display: block;
  @media (max-width: 475px) {
    width: 100%;
  }
`;

export const FooterLogo = styled.img`
  width: 120px;
`;
